import loadable from '@loadable/component'
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateBilanRoute from './components/protected-route/private-bilan-route'
import PrivateDefisRoute from './components/protected-route/private-defis-route'
import PrivateThanksRoute from './components/protected-route/private-thanks-route'
import ChangePasswordFirstConnexion from './pages/forgot-password/change-password-first-connexion'
const Dashboard = loadable(() => import('./pages/dashboard/index'))
const SsoError = loadable(() => import('./pages/login/Soo-error'))
const LoaderSsso = loadable(() => import('./pages/sso/loader'))
const Thanks = loadable(() => import('./pages/dashboard/thanks/thanks'))
const MentionLegale = loadable(() =>
  import('./pages/metion-legale/metion-legale'),
)

const ChatBot = loadable(() => import('./pages/dashboard/bot/bot'))
const Greet = loadable(() => import('./pages/dashboard/greet/greet'))
const Univers = loadable(() => import('./pages/dashboard/univers/univers'))
const Footer = loadable(() => import('./components/footer/Footer'))

const PublicRoute = loadable(() =>
  import('./components/public-route/public-route'),
)

const PrivateRoute = loadable(() =>
  import('./components/protected-route/protected-route'),
)
const Login = loadable(() => import('./pages/login/Login'))
const LoginError = loadable(() => import('./pages/login/Login-error'))

const RessourcesView = loadable(() => import('./pages/ressources/ressources'))

const ForgotPassword = loadable(() =>
  import('./pages/forgot-password/forgot-password'),
)
const ResetPassword = loadable(() =>
  import('./pages/forgot-password/reset-password'),
)

const Header = loadable(() => import('./components/header/Header'))

const RegisterPage = loadable(() => import('./pages/register/Register'))

const Questionnaire = loadable(() =>
  import('./pages/questionnaire/questionnaire'),
)

const QuizNotFinished = loadable(() =>
  import('./pages/quiz-not-finished/quiz-not-finished'),
)

const StepperQuestion = loadable(() =>
  import('./pages/stepper-question/stepper-question'),
)

const Bilan = loadable(() => import('./pages/dashboard/bilan/bilan'))
const Access = loadable(() => import('./pages/login/Access'))

const HistoriqueBilan = loadable(() =>
  import('./pages/dashboard/historique-bilan/bilan-historique'),
)

const RegisterSuccess = loadable(() =>
  import('./pages/register/Register-success'),
)

const Defis = loadable(() => import('./pages/dashboard/defis/defis'))

const DetailsDefis = loadable(() => import('./pages/dashboard/defis/details'))

const Ressource = loadable(() =>
  import('./pages/dashboard/ressources/ressources'),
)

const NotFound = loadable(() => import('./pages/not-found/not-found'))

const QuizBilan = loadable(() => import('./pages/quiz-bilan/quiz-bilan'))

const RoutesGogx: React.FC = () => {
  const [arrowVisible, setArrowVisible] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight
      const footerHeight = document.getElementById('footer')!.offsetHeight
      const pageHeight = document.body.offsetHeight

      const scrollPosition = window.scrollY

      //  scrollPosition + windowHeight + 500,
      if (scrollPosition + windowHeight >= pageHeight - footerHeight) {
        arrowVisible && setArrowVisible(false)
      } else {
        setArrowVisible(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Nettoyage de l'événement lors du démontage du composant
    return () => window.removeEventListener('scroll', handleScroll)
  }, [arrowVisible, setArrowVisible])

  function routerChange() {
    setTimeout(() => {
      setArrowVisible(true)
    }, 500) // 500 millisecondes = 0,5 seconde
  }

  return (
    <BrowserRouter>
      <Header routeChange={routerChange} />
      <div className="main">
        <Routes>
          <Route index element={<PublicRoute component={Login} />} />
          <Route
            path="auth/openid"
            element={<PublicRoute component={LoaderSsso} />}
          />
          <Route
            path="terms-of-sales/engram-cogx"
            element={<MentionLegale />}
          />
          <Route
            path="register/cog-x"
            element={<PublicRoute component={RegisterPage} />}
          />

          <Route
            path="access/denied"
            element={<PublicRoute component={Access} />}
          />

          <Route
            path="register-success"
            element={<PublicRoute component={RegisterSuccess} />}
          />

          <Route
            path="account-not-valid"
            element={<PublicRoute component={LoginError} />}
          />

          <Route
            path="sso-not-valid"
            element={<PublicRoute component={SsoError} />}
          />

          <Route
            path="reset-password"
            element={<PublicRoute component={ResetPassword} />}
          />

          <Route
            path="change-password"
            element={<ChangePasswordFirstConnexion />}
          />

          <Route
            path="forgot-password"
            element={<PublicRoute component={ForgotPassword} />}
          />
          <Route path="quiz/:id" element={<Questionnaire />} />
          <Route
            path="quiz-bilan"
            element={<PrivateRoute component={QuizBilan} />}
          />

          <Route
            path="quiz-not-finished"
            element={<PrivateRoute component={QuizNotFinished} />}
          />

          <Route
            path="quiz/questionnaires/:title/:id"
            element={<PrivateRoute component={StepperQuestion} />}
          />

          <Route path="dashboard">
            <Route
              index={true}
              element={<PrivateRoute component={Dashboard} />}
            />
            <Route
              path="bilan"
              element={
                <PrivateBilanRoute component={Bilan} isVisible={arrowVisible} />
              }
            />
            <Route
              path="historique-bilan/:id"
              element={<PrivateRoute component={Bilan} />}
            />
            <Route
              path="history-bilan"
              element={<PrivateRoute component={HistoriqueBilan} />}
            />

            <Route
              path="defis"
              element={
                <PrivateDefisRoute component={Defis} isVisible={arrowVisible} />
              }
            />

            <Route
              path="univers"
              element={
                <PrivateRoute component={Univers} isVisible={arrowVisible} />
              }
            />

            <Route
              path="defis/:id/:name"
              element={<PrivateRoute component={DetailsDefis} />}
            />
            <Route
              path="ressource"
              element={<PrivateRoute component={Ressource} />}
            />

            <Route
              path="chat-bot"
              element={<PrivateRoute component={ChatBot} />}
            />
            <Route path="greet" element={<PrivateRoute component={Greet} />} />
            <Route
              path="thanks"
              element={<PrivateThanksRoute component={Thanks} />}
            />
            <Route
              path="view-ressource/:id/:name"
              element={<PrivateRoute component={RessourcesView} />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  )
}

export default RoutesGogx
